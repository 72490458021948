import numeral from 'numeral'
import moment from 'moment'

import 'moment/locale/es'
import 'numeral/locales/es'

moment.locale('es')
numeral.locale('es')

const $ = window.$

export function toggleModal(id) {
  $('#' + id).modal('toggle')
}

export const MAX_NUMBER = 999999999999999

export function empty(s) {
  return !s || s === null || (s.trim ? s.trim().length === 0 : false)
}

export function int(s) {
  return parseInt(s, 10)
}

export function str(v) {
  return v && v !== null && v.toString ? v.toString() : ''
}

export function clp(value) {
  return numeral(value).format('$0,0')
}

const EMAIL_REG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
export function isEmail(email) {
  return EMAIL_REG.test(email);
}

export function now() {
  return moment().format('DD/MM/YYYY HH:mm:ss')
}

export function formatTime(date) {
  return moment(date).format('HH:mm:ss')
}

export function formatDatetime(date) {
  return moment(date).format('DD/MM/YYYY HH:mm:ss')
}

export const nowWithMonthBefore = (m, mask) => moment().subtract(m, 'months').format(mask)
export const nowDate = (mask) => moment().format(mask)

export function reduceProductos(t, v) {
  return t += v.precio * v.cantidad
}

export function reduceProductosFromPedido(t, v) {
  return t += v.productos.reduce(reduceProductos, 0)
}

export {
  moment, numeral
}