import { FIJAR_ERROR, LIMPIAR_ERROR } from "./Types";

export function fijarError(e) {
  console.error('FIJAR ERROR', e)
  if (!e || e === null) return e

  let message = e.message ? e.message : e
  message = message.statusText ? message.statusText : message
  message = message.status ? message.data : message

  return {
    type: FIJAR_ERROR,
    message: (typeof message === 'string' ? message : 'Error Interno')
  }
}

export function limpiarError() {
  return {
    type: LIMPIAR_ERROR
  }
}