import { CREAR_SESION, DESTRUIR_SESION } from "./Types";
import { crearSesion, destruirSesion } from "../api";


export function nuevaSesion(email, codigo) {
  return dispatch => crearSesion(email, codigo).then(res => {
    console.log(res)
    if (res.status === 'success') {
      dispatch({
        type: CREAR_SESION,
        ...res.data
      })
    }
    return res
  })
}

export function eliminarSesion() {
  return dispatch => destruirSesion().finally(() => dispatch({ type: DESTRUIR_SESION }))
}