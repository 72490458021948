import createReducer from './createReducer'
import { FIJAR_ERROR, LIMPIAR_ERROR } from '../actions/Types'

const initialState = {
  message: ''
}

export const errores = createReducer(initialState, {
  [FIJAR_ERROR]: (state, { message }) => ({ ...state, message }),
  [LIMPIAR_ERROR]: () => ({ ...initialState })
})