import React from 'react'
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { DASHBOARD, PRODUCTOS, JORNADAS, CLIENTES } from "../utils/Routes";
import { eliminarSesion } from '../actions/SessionActions';


const Header = ({ history, logout, correo }) => {
  let current = history.location.pathname
  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="#">Backoffice</a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav mr-auto">
          <MenuItem label="Ventas" to={DASHBOARD} current={current} />
          <MenuItem label="Jornadas" to={JORNADAS} current={current} />
          <MenuItem label="Clientes" to={CLIENTES} current={current} />
          <MenuItem label="Productos" to={PRODUCTOS} current={current} />
        </div>
        <button type="button" onClick={logout} className="btn btn-link navbar-text">
          {correo} <i className="fal fa-sign-out fa-lg ml-1"></i>
        </button>
      </div>
    </nav>
  )
}

const MenuItem = ({ label, to, current }) => (
  <Link className={"nav-item nav-link" + (to === current ? ' active' : '')} to={to}>
    {label}
  </Link>
)

const mapStateToProps = ({ sesion }) => ({
  correo: sesion.email
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(eliminarSesion())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)