import React, { Component } from 'react'
import { clientes } from '../api'

export default class Usuarios extends Component {
  state = {
    clientes: []
  }

  componentDidMount() {
    clientes().then(res => {
      if (res.status !== 'success') {
        return console.log(res)
      }
      this.setState({ clientes: res.data })
    })
  }

  render() {
    return (
      <div className="container">
        <ul className="list-group">
          {this.state.clientes.map(Cliente)}
        </ul>
      </div>
    )
  }
}

const Cliente = ({ id, email, telefonos, nombre, direcciones }) => (
  <li key={id} className="list-group-item">
    <p className="mb-1"><strong>{nombre}</strong></p>
    <div>{email}</div>
    <div>{direcciones}</div>
    <div>{telefonos && telefonos.map(Telefono)}</div>
    <small className="text-muted">{id}</small>
  </li>
)

const Telefono = (v) => (
  <span key={v} className="badge badge-primary">+56 {v}</span>
)