import { FIJAR_TAGS } from "./Types";
import { tagsGastos } from "../api";


export function init() {
  return dispatch => {
    return fetchTagsGastos()(dispatch)
  }
}


function fetchTagsGastos() {
  return dispatch => tagsGastos().then(res => {
    if (res.status === 'success') {
      dispatch({
        type: FIJAR_TAGS,
        tags: res.data
      })
      return res
    }
    return Promise.reject(res.data)
  })
}