import React, { Component } from 'react';
import './App.css';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'

import { Header, Spinner } from "./components";
import { Login, Dashboard, Productos, Jornadas, Usuarios, Venta,
  JornadaDetalle, AgregarGasto } from "./screens";
import { DASHBOARD, CLIENTES, JORNADAS, NUEVA_VENTA, PRODUCTOS,
  JORNADA_DETALLE, AGREGAR_GASTO } from "./utils/Routes";
import { limpiarError } from "./actions/ErroresActions";
import { empty } from './utils';
import { init } from './actions';


class App extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    if (this.props.autorizado) {
      this.init()
    } else {
      this.loading()
    }
  }

  init = () => {
    this.loading()
    return this.props.init().finally(this.loading)
  }

  loading = () => this.setState({ loading: !this.state.loading })

  render() {
    const { loading, autorizado, error, limpiarError } = this.props
    if (!autorizado) return <Login init={this.props.init} />
    if (loading) return <Spinner />

    return (
      <Router>
        <div>
          <Route component={Header} />
          <div className="cs-header-margin">
            <ErrorMessage message={error} onClick={limpiarError} />
            <Switch>
              <Route exact path={DASHBOARD} component={Dashboard} />
              <Route path={NUEVA_VENTA} component={Venta} />
              <Route path={AGREGAR_GASTO} component={AgregarGasto} />
              <Route path={PRODUCTOS} component={Productos} />
              <Route exact path={JORNADAS} component={Jornadas} />
              <Route path={JORNADA_DETALLE} component={JornadaDetalle} />
              <Route path={CLIENTES} component={Usuarios} />
            </Switch>
          </div>
        </div>
      </Router>
    )
  }
}

const ErrorMessage = ({ message, onClick }) => !empty(message) && (
  <div className="container alert alert-danger" role="alert">
    ERROR: <strong>{message}</strong>
    <button type="button" className="close" aria-label="Close" onClick={onClick}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
)

const mapStateToProps = ({ sesion, errores }) => ({
  autorizado: !empty(sesion.token),
  error: errores.message
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ init, limpiarError }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
