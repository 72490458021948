import { get, post, del } from "./Methods";

export function enviarCorreo(email) {
  return post('/email', { email })
}

export function crearSesion(email, codigo) {
  return post('/sesion', { email, codigo })
}

export function destruirSesion() {
  return del('/sesion')
}

export function jornadas() {
  return get('/jornadas')
}

export function abrirJornada() {
  return post('/jornadas')
}

export function jornadaActual() {
  return get('/jornadas/activa')
}

export function jornadaPedidos(id) {
  return get(`/jornadas/${id}/pedidos`)
}

export function cerrarJornada() {
  return post('/jornadas/cerrar')
}

export function pedidos() {
  return get('/pedidos')
}

export function ingresarPedido(pedido) {
  return post('/pedidos', pedido)
}

export function gastos() {
  return get('/gastos')
}

export function tagsGastos() {
  return get('/gastos/tags')
}

export function ingresarGasto(gasto) {
  return post('/gastos', gasto)
}

export function productos() {
  return get('/productos')
}

export function clientes() {
  return get('/clientes')
}