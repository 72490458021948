import createReducer from './createReducer'
import { CREAR_SESION, DESTRUIR_SESION, FIJAR_SESION } from '../actions/Types'

const initialState = {
  token: '',
  email: '',
  nombre: '',
  sucursal: 0,
  permisos: 0
}

export const sesion = createReducer(initialState, {
  [CREAR_SESION]: (state, { token, email }) => ({ ...state, token, email }),
  [DESTRUIR_SESION]: () => ({ ...initialState }),
  [FIJAR_SESION]: (state, { permisos, sucursal }) => ({
    ...state, sucursal, permisos: isNaN(permisos) ? 0 : permisos
  })
})