import React, { Component } from 'react'
import { productos } from "../api";
import { clp } from '../utils';

export default class Productos extends Component {
  state = {
    productos: []
  }

  componentDidMount() {
    productos().then(res => {
      if (res.status !== 'success') {
        return console.error(res)
      }
      this.setState({ productos: res.data })
    })
  }

  render() {
    return (
      <div className="container">
        <ul className="list-group">
          {this.state.productos.map(Producto)}
        </ul>
      </div>
    )
  }
}

const Producto = ({ nombre, descripcion, id, precio }) => (
  <li key={id} className="list-group-item">
    <div className="row">
      <div className="col-1">{id}</div>
      <div className="col-11 d-flex w-100 justify-content-between">
        <div>
          <h5>{nombre}</h5>
          <small>{descripcion}</small>
        </div>
        <div>{clp(precio)}</div>
      </div>
    </div>
  </li>
)