import React, { Component } from 'react'
import { moment, clp, reduceProductos, toggleModal, empty } from "../utils";

import { productos, ingresarPedido, clientes } from '../api'
import { DASHBOARD } from '../utils/Routes';
import DropdownList from '../components/DropdownList';


export default class Venta extends Component {
  state = {
    modal: false,
    filtro: '',
    clientes: [],
    productos: [],
    data: [],
    run: '',
    nombre: '',
    telefono: '',
    correo: '',
    direccion: '',
    mensaje: ''
  }

  componentDidMount() {
    clientes().then(res => {
      if (res.status === 'success') {
        this.setState({ clientes: res.data })
      }
    })
    productos().then(res => {
      if (res.status !== 'success') {
        return console.log(res.data)
      }
      this.setState({ data: res.data })
    }).catch(console.error)
  }

  submit = () => {
    let { productos, run, nombre, correo, direccion, telefono, mensaje } = this.state
    let pedido = {
      cliente: {
        nombre: nombre.trim(),
        run: run.trim(),
        email: correo.trim(),
        telefonos: empty(telefono) ? undefined : [telefono.trim()],
        direcciones: empty(direccion) ? undefined : [direccion.trim()]
      },
      mensaje,
      productos,
    }
    ingresarPedido(pedido).then(this.volver).catch(console.error)
  }

  volver = () => this.props.history.push(DASHBOARD)

  agregarProducto = (producto) => {
    if (this.state.productos.find(p => p.id === producto.id)) {
      this.setState({ productos: this.state.productos.map(p => ({
        ...p, cantidad: p.id === producto.id ? p.cantidad + 1 : p.cantidad
      }))})
    } else {
      this.setState({ productos: [...this.state.productos, { ...producto, cantidad: 1 }]})
    }
  }

  quitarProducto = (id) => {
    this.setState({ productos: this.state.productos.map(p => ({
      ...p, cantidad: p.id === id ? p.cantidad - 1 : p.cantidad
    })).filter(p => p.cantidad > 0)})
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  seleccionar = (cliente) => {
    const { id, nombre, run, direccion, correo, telefonos } = cliente

    console.log(cliente)
    this.setState({
      filtro: '',
      id,
      run: run ? run : this.state.run,
      nombre: nombre ? nombre : this.state.nombre,
      direccion: direccion ? direccion : this.state.direccion,
      correo: correo ? correo : this.state.correo,
      telefono: (telefonos && telefonos.length > 0) ? telefonos[0] : this.state.telefono
    })
  }

  clear = () => {
    this.setState({
      filtro: '',
      id: undefined,
      run: '',
      nombre: '',
      direccion: '',
      correo: '',
      telefono: ''
    })
  }

  toggleProductos = () => toggleModal('productosModal')

  render() {
    const { productos, nombre, run, direccion, correo, telefono, mensaje, clientes, filtro } = this.state
    const disabled = productos.length === 0

    const filtrados = empty(filtro) ? [] : clientes.filter(c => c.nombre.toLowerCase().indexOf(filtro.trim().toLowerCase()) > -1)

    return (
      <div className="container">
        <button className="btn btn-primary btn-block btn-lg mt-3 mb-3" type="button" onClick={this.submit} disabled={disabled}>
          Ingresar Venta ({clp(productos.reduce(reduceProductos, 0))})
        </button>
        <div className="card">
          <div className="card-body">
            <form className="form-row">
              <div className="form-group col-12">
                <input id="filtro" className="form-control" placeholder="Buscar Cliente" value={filtro} onChange={this.onChange} />
                <DropdownList data={filtrados} onClick={this.seleccionar} />
              </div>
              {this.state.id &&
                <div className="form-group col-12">
                  <div>Cliente seleccionado: <strong>{this.state.id}</strong> <button type="button" className="btn btn-warning btn-sm" onClick={this.clear}>
                    <i className="fas fa-times"></i></button></div>
                </div>
              }
              <div className="form-group col-md-6">
                <label>Rut</label>
                <div className="input-group">
                  <input id="run" className="form-control" placeholder="Digito automatico" value={run} onChange={this.onChange} />
                  <div className="input-group-append">
                    <span className="input-group-text">- 4</span>
                  </div>
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Nombre</label>
                <input className="form-control" placeholder="" id="nombre" value={nombre} onChange={this.onChange} />
              </div>
              <div className="form-group col-md-6">
                <label>Telefono</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">+56</span>
                  </div>
                  <input className="form-control" placeholder="Numero de telefono" id="telefono" value={telefono} onChange={this.onChange}  />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label>Correo</label>
                <input className="form-control" placeholder="correo@electronico.cl" id="correo" value={correo} onChange={this.onChange} />
              </div>
              <div className="form-group col-md-6">
                <label>Direccion</label>
                <input className="form-control" placeholder="Direccion de despacho" id="direccion" value={direccion} onChange={this.onChange} />
              </div>
              <div className="form-group col-md-6">
                <label>Mensaje</label>
                <input id="mensaje" className="form-control" value={mensaje} onChange={this.onChange} />
              </div>
            </form>
          </div>
          <ul className="list-group">
            <li className="list-group-item">
              <button className="btn btn-link btn-block" type="button" onClick={this.toggleProductos}><i className="fas fa-plus"> Agregar Producto</i></button>
            </li>
            {this.state.productos.map(p => (
              <li key={p.id} className="list-group-item" onClick={() => this.quitarProducto(p.id)}><Producto { ...p } /></li>
            ))}
            <li className="list-group-item">
              <button className="btn btn-link btn-block" type="button" onClick={() => this.props.history.goBack()}>Volver</button>
            </li>
          </ul>
          
          <ProductosModal productos={this.state.data} onClick={this.agregarProducto} />
        </div>
      </div>
    )
  }
}

const ProductosModal = ({ productos, onClick }) => (
  <div id="productosModal" className="modal" tabIndex="-1" role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <input className="form-control" placeholder="Buscar" />
          <button type="button" className="btn btn-link" data-dismiss="modal" aria-label="Close">
            <i className="fas fa-times fa-lg"></i>
          </button>
        </div>
        <ul className="list-group">
          {productos.map(p => (
            <li key={p.id} className="list-group-item" onClick={() => onClick(p)}>
              <Producto {...p} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
)

const Producto = ({ nombre, descripcion, precio, cantidad }) => (
  <div className="d-flex justify-content-between">
    <div>
      <p><strong>{nombre}</strong> <span className="badge badge-pill badge-primary">{cantidad}</span></p>
      <small>{descripcion}</small>
    </div>
    <div>{clp(precio)}</div>
  </div>
)