import React, { Component } from 'react'
import { NUEVA_VENTA, AGREGAR_GASTO } from "../utils/Routes";
import { connect } from 'react-redux';

import { clp, reduceProductosFromPedido, moment } from "../utils";
import { pedidos, jornadaActual, cerrarJornada, abrirJornada, gastos } from '../api'
import { fijarError } from '../actions/ErroresActions';
import { PedidoItem } from '../components';
import { ContainerSpinner } from '../components';
import { productoFinalizado } from '../utils/Estados';



function sortIndice(a, b) {
  return b.indice - a.indice
}

class Dashboard extends Component {
  state = {
    pedidos: [],
    gastos: [],
    actual: null,
    show: 0,
    loading: true
  }

  componentDidMount() {
    this.refresh().finally(() => this.setState({ loading: false }))
  }

  refresh = () => {
    const promisePedido = pedidos().then(res => {
      if (res.status === 'success') {
        this.setState({ pedidos: res.data })
      } else if (res.status === 'fail') {
        this.setState({ pedidos: []})
      } else {
        return Promise.reject(res)
      }
    })
    const promiseJornada = jornadaActual().then(res => {
      if (res.status === 'success') {
        this.setState({ actual: res.data })
      } else {
        return Promise.reject(res)
      }
    })
    const promiseGastos = gastos().then(res => {
      if (res.status === 'success') {
        this.setState({ gastos: res.data })
      } else {
        this.setState({ gastos: [] })
      }
    })

    return Promise.all([promisePedido, promiseJornada, promiseGastos]).catch(this.props.fijarError)
  }

  cerrarJornada = () => {
    cerrarJornada().then(this.refresh).catch(this.props.fijarError)
  }

  abrirJornada = () => {
    abrirJornada().then(this.refresh).catch(this.props.fijarError)
  }

  nuevaVenta = () => this.props.history.push(NUEVA_VENTA)
  agregarGasto = () => this.props.history.push(AGREGAR_GASTO)

  show = (show) => this.setState({ show })

  render() {
    const { pedidos, gastos, actual, show, loading } =  this.state
    if (loading) return <ContainerSpinner />
    const totalVentas = pedidos.filter(productoFinalizado).reduce(reduceProductosFromPedido, 0)
    const totalGastos = gastos.reduce((t, v) => t += (v.monto * v.cantidad), 0)

    return (
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-4 text-center">
                <h5>{clp(totalVentas)}</h5>
                <small>Total Ventas</small>
              </div>
              <div className="col-4 text-center">
                <h5>{clp(totalGastos)}</h5>
                <small>Total Gastos</small>
              </div>
              <div className="col-4 text-center">
                <h5>{clp(totalVentas - totalGastos)}</h5>
                <small>Ganancia</small>
              </div>
            </div>
          </div>
        </div>
        <SinJornada actual={actual} />
        <div className="row">
          <div className="col-12 text-right">
            <button className="btn btn-success m-1" type="button" onClick={this.nuevaVenta} disabled={actual === null}>
              <i className="fas fa-plus"></i>
            </button>
            <button className="btn btn-primary m-1" type="button" onClick={this.agregarGasto} disabled={actual === null}>
              <i className="fas fa-plus"></i>
            </button>
            {actual === null ? <AbrirJornada onClick={this.abrirJornada} /> : <CerrarJornada onClick={this.cerrarJornada} />}
          </div>
        </div>
        <ul className="list-group">
          <li className="list-group-item">
            <div className="row text-center">
              <ListButton current={show} onClick={this.show} label="Ventas" id={0} />
              <ListButton current={show} onClick={this.show} label="Compras" id={1} />
              
            </div>
          </li>
          {show === 0 && pedidos.sort(sortIndice).map(PedidoItem)}
          {show === 1 && gastos.sort((a, b) => b.id - a.id).map(g => <GastoItem key={g.id} { ...g} tagsData={this.props.tags} />)}
        </ul>
      </div>
    )
  }
}

const ListButton = ({ current, onClick, label, id }) => (
  <button className={"col-6 btn btn-link" + (current === id ? "" : " text-white")} type="button" onClick={() => onClick(id)}>{label}</button>
)

const GastoItem = ({ id, tags, fecha, descripcion, monto, cantidad, tagsData }) => (
  <li key={id} className="list-group-item">
    <div className="row">
      <div className="col-1 text-center">{id}</div>
      <div className="col-11">
        <div className="d-flex justify-content-between">
          <div>
            <p className="mb-1">{descripcion} <span className="badge badge-pill badge-primary">{cantidad}</span></p>
            <small className="text-muted">{tags.map(t => (<div key={t}>{resolveTag(t, tagsData)}</div>))}</small>
            <div>{clp(monto)}</div>
          </div>
          <div className="d-flex flex-column justify-content-between">
            <small>{moment(fecha).fromNow()}</small>
            <div>Total {clp(monto * cantidad)}</div>
          </div>
        </div>
      </div>
    </div>
  </li>
)

function resolveTag(t, data) {
  const v = data.find(d => d.id === t)
  return v ? v.tag : ''
}

const SinJornada = ({ actual }) => actual === null && (
  <div className="alert alert-warning text-center mt-3" role="alert">
    No hay jornada activa
  </div>
)

const AbrirJornada = ({ onClick }) => (
  <button className="btn btn-success m-1" type="button" onClick={onClick}>
    <i className="fas fa-check-circle"> Abrir Jornada</i>
  </button>
)

const CerrarJornada = ({ onClick }) => (
  <button className="btn btn-danger m-1" type="button" onClick={onClick}>
    <i className="fas fa-times-circle"> Cerrar Jornada</i>
  </button>
)

const mapStateToProps = ({ tags }) => ({ tags })

const mapDispatchToProps = (dispatch) => ({
  fijarError: (e) => dispatch(fijarError(e))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
