import { host } from "../config";
import { store } from "../Store";


export function get(path) {
  return request(path, 'GET')
}

export function post(path, body) {
  return request(path, 'POST', body)
}

export function del(path, body) {
  return request(path, 'DELETE', body)
}

function request(path, method, body) {
  let { token } = store.getState().sesion
  
  return fetch(host + path, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'TOKEN': token
    },
    body: body ? JSON.stringify(body) : undefined
  }).then(r => r.json()).catch(error)
}

function error(e) {
  console.error('[API]', e)
  if (e.statusText) {
    return Promise.reject({ status: e.status, data: e.statusText })
  }
  if (e.message) {
    return Promise.reject({ status: 500, data: e.message })
  }
  return Promise.reject(e)
}