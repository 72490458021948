import React from 'react'

export default ({ data, onClick }) => data.length > 0 && (
  <div className="cs-floating bg-light rounded">
    {data.map(d => (
      <div key={d.id} className="list-group-item" onClick={() => onClick(d)}>
        {d.nombre}
      </div>
    ))} 
  </div>
)