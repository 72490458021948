import React, { Component } from 'react'
import { jornadaPedidos } from '../api'
import { PedidoItem, ContainerSpinner } from '../components'
import { FINALIZADO, productoFinalizado } from '../utils/Estados'
import { reduceProductosFromPedido, clp } from '../utils'

export default class JornadaDetalle extends Component {
  state = {
    pedidos: [],
    loading: true
  }

  componentDidMount() {
    jornadaPedidos(this.props.match.params.id).then(res => {
      if (res.status === 'success') {
        this.setState({ pedidos: res.data })
      }
    }).finally(() => this.setState({ loading: false }))
  }

  render() {
    const { pedidos, loading } = this.state
    if (loading) return <ContainerSpinner />

    const totalVentas = pedidos.filter(productoFinalizado).reduce(reduceProductosFromPedido, 0)

    return (
      <div className="container">
        {pedidos.length === 0 && <Empty />}
        <div className="card">
          <div className="card-body">
            <div className="text-center">
              <h5><strong>{clp(totalVentas)}</strong></h5>
              <small>Total Ventas</small>
            </div>
          </div>
        </div>
        <ul className="list-group">
          {pedidos.sort(sortPedidos).map(PedidoItem)}
        </ul>
      </div>
    )
  }
}

const Empty = () => (
  <h5 className="text-center">No hay pedidos.</h5>
)

function sortPedidos(a, b) {
  return b.indice - a.indice
}
