import React from 'react'

import { moment, clp, reduceProductos, empty } from "../utils";
import { FINALIZADO } from "../utils/Estados";


export default ({ id, indice, cliente, fecha, productos, estado, mensaje }) => (
  <li key={id} className="list-group-item">
    <div className="row">
      <div className="col-1 text-center">
        <div>{indice}</div>
        <Estado estado={estado} />
      </div>
      <div className="col-11">
        <div className="d-flex w-100 justify-content-between">
          <div>
            <div className="mb-1">{cliente !== null ? <Cliente { ...cliente } /> : 'Anonimo'}</div>
            <p className="mb-1">{mensaje}</p>
          </div>
          <div className="text-right">
            <small>{moment(fecha).fromNow()}</small>
            <p className="p-3"><strong>{clp(productos.reduce(reduceProductos, 0))}</strong></p>
          </div>
        </div>
        <ul className="list-group list-group-flush">
          {productos.map(Producto)}
        </ul>
      </div>
    </div>
  </li>
)

const Cliente = ({ nombre, telefonos, email, run, direccion }) => (
  <div>
    <h5>{empty(nombre) ? 'Anonimo' : nombre}</h5>
    <div>{run}</div>
    <div>{telefonos && telefonos.map(Telefono)}</div>
    <small>{direccion} {email}</small>
  </div>
)

const Telefono = (v) => (
  <span key={v} className="badge badge-primary">+56 {v}</span>
)

const Producto = ({ id, nombre, descripcion, precio, cantidad }) => (
  <li key={id} className="list-group-item">
    <div className="d-flex w-100 justify-content-between">
      <div>
        <div><strong>{nombre}</strong> <span className="badge badge-pill badge-primary">{cantidad}</span></div>
        <small>{descripcion}</small>
      </div>
      <div>{clp(precio * cantidad)}</div>
    </div>
  </li>
)

const Estado = ({ estado }) => (
  <div className="text-center mt-3">
    <div>{estado === FINALIZADO ? <PedidoSuccess /> : <PedidoWarning />}</div>
    <small>{estado}</small>
  </div>
)

const PedidoSuccess = () => (
  <i className="fas fa-check text-success"></i>
)

const PedidoWarning = () => (
  <i className="fas fa-exclamation-triangle text-warning"></i>
)