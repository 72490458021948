import React, { Component } from 'react'
import { connect } from "react-redux";

import { empty, int, clp } from "../utils";

import { ingresarGasto, tagsGastos } from '../api'


class AgregarGasto extends Component {
  state = {
    tag: '',
    descripcion: '',
    monto: '',
    cantidad: '1',
    loading: false
  }

  submit = () => {
    const { tag, descripcion, monto, cantidad } = this.state
    const gasto = {
      tag, 
      descripcion: descripcion.trim(),
      monto: int(monto),
      cantidad: int(cantidad)
    }

    console.log(gasto)
    this.setState({ loading: true })
    ingresarGasto(gasto).then(res => {
      if (res.status !== 'success') {
        console.error('No se pudo agregar el gasto', res.data)
        return Promise.reject()
      }
    }).then(this.volver).catch(e => {
      console.error(e)
      this.setState({ loading: false })
    })
  }

  volver = () => this.props.history.goBack()

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  render() {
    const { descripcion, monto, cantidad, tag, loading } = this.state
    const disabled = empty(tag) || empty(monto) || empty(cantidad) || isNaN(monto) || isNaN(cantidad) || loading

    return (
      <div className="container">
        <form>
          <div className="form-group">
            <label htmlFor="tag">*Gasto</label>
            <select className="form-control" id="tag" value={tag} onChange={this.onChange}>
              <option value="">----------------</option>
              {this.props.tags.map(t => (
                <option key={t.id} value={t.id}>{t.tag}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="descripcion">Descripcion</label>
            <input className="form-control" id="descripcion" value={descripcion} onChange={this.onChange} />
          </div>
          <div className="form-group">
            <label htmlFor="monto">*Monto</label>
            <input className="form-control" id="monto" value={monto} onChange={this.onChange} />
          </div>
          <div className="form-group">
            <label htmlFor="cantidad">*Cantidad</label>
            <input className="form-control" id="cantidad" value={cantidad} onChange={this.onChange} />
          </div>
        </form>
        <p>Total {clp(int(monto) * int(cantidad))}</p>
        <button className="btn btn-primary btn-block" type="button" disabled={disabled} onClick={this.submit}>
          {loading ? '...' : 'Agregar'}
        </button>
        <button className="btn btn-link btn-block" type="button" onClick={this.volver}>Volver</button>
      </div>
    )
  }
}

const mapStateToProps = ({ tags }) => ({ tags })

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AgregarGasto);
