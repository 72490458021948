import React, { Component } from 'react'
import { connect } from "react-redux";

import { empty, int } from '../utils'
import { nuevaSesion } from '../actions/SessionActions';
import { enviarCorreo } from '../api';


class Login extends Component {
  state = {
    correo: '',
    codigo: '',
    step: 0,
    error: '',
    loading: false
  }

  enviarCorreo = () => {
    this.setState({ loading: true, error: '' })
    enviarCorreo(this.state.correo.trim()).then(res => {
      console.log(res)
      if (res.status === 'success') {
        this.setState({ step: 1 })
      } else {
        this.setState({ error: res.data })
      }
    }).catch(this.error).finally(() => this.setState({ loading: false }))
  }

  submit = () => {
    let { correo, codigo } = this.state

    this.loading()
    this.setState({ error: '' })
    this.props.nuevaSesion(correo.trim(), int(codigo)).then(res => {
      if (res.status !== 'success') {
        this.setState({ error: res.data })
      }
    }).catch(this.error).finally(this.loading)
  }

  error = (e) => {
    console.error(e)
    let error = e.data ? e.data : 'Error interno'
    error = e.statusText ? e.statusText : error
    error = e.message ? e.message : error

    this.setState({ error })
  }

  loading = () => this.setState({ loading: this.state.loading })

  onChange = (e) => this.setState({ [e.target.id]: e.target.value })

  volver = () => this.setState({ step: 0, error: '' })

  render() {
    let { step, correo, codigo, loading, error } = this.state

    return (
      <div className="container mt-5">
        <h2 className="text-center">Backoffice</h2>
        <div className="d-flex flex-column align-items-center mt-5">
          <div className="col-6">
            <AlertDanger message={error} />
          </div>
          {step === 0 ?
            <CorreoForm correo={correo} loading={loading} onClick={this.enviarCorreo} onChange={this.onChange} /> :
            <CodigoForm correo={correo} codigo={codigo} loading={loading}
              volver={this.volver} onClick={this.submit} onChange={this.onChange} />}
        </div>
      </div>
    )
  }
}

const AlertDanger = ({ message }) => !empty(message) && (
  <div className="alert alert-danger" role="alert">
    {message}
  </div>
)

const CorreoForm = ({ correo, onClick, onChange, loading }) => (
  <form className="col-6">
    <div className="form-group">
      <label>Correo</label>
      <input id="correo" className="form-control" placeholder="email@correo.cl" value={correo} onChange={onChange} />
      <small>* Enviaremos un codigo a tu correo para que puedas ingresar</small>
    </div>
    <button className="btn btn-primary btn-block" type="button" onClick={onClick} disabled={empty(correo) || loading}>Enviar correo</button>
  </form>
)

const CodigoForm = ({ correo, codigo, loading, onClick, onChange, volver }) => (
  <form className="col-6">
    <div className="form-group">
      <label>Codigo</label>
      <input id="codigo" className="form-control" value={codigo} onChange={onChange} />
      <small>* Ingresa el codigo enviado a {correo} </small>
    </div>
    <button className="btn btn-primary btn-block" type="button" onClick={onClick} disabled={empty(codigo) || loading}>Ingresar</button>
    <button className="btn btn-link btn-block" type="button" onClick={volver} >Volver</button>
  </form>
)

const mapDispatchToProps = (dispatch) => ({
  nuevaSesion: (email, codigo) => dispatch(nuevaSesion(email, codigo))
})

export default connect(null, mapDispatchToProps)(Login)