import { combineReducers } from 'redux'

import createReducer from './createReducer'
import { sesion } from './SessionReducer'
import { errores } from "./ErroresReducer";
import { FIJAR_TAGS } from '../actions/Types';



const tags = createReducer([], {
  [FIJAR_TAGS]: (state, { tags }) => tags
})


export default combineReducers({
  sesion, errores, tags
})
