import React, { Component } from 'react'
import { jornadas } from '../api'
import { moment } from '../utils'
import { JORNADA_DETALLE } from '../utils/Routes'
import { ContainerSpinner } from '../components'

export default class Jornadas extends Component {
  state = {
    jornadas: [],
    loading: true
  }

  componentDidMount() {
    jornadas().then(res => {
      if (res.status !== 'success') {
        return console.log(res)
      }
      this.setState({ jornadas: res.data })
    }).finally(() => this.setState({ loading: false }))
  }

  detalle = (id) => this.props.history.push(JORNADA_DETALLE.replace(':id', id))

  render() {
    if (this.state.loading) return <ContainerSpinner />
    return (
      <div className="container">
        <ul className="list-group">
          {this.state.jornadas.sort(sortJornadas)
            .map(jornada => <Jornada key={jornada.id} { ...jornada } onClick={this.detalle} />)}
        </ul>
      </div>
    )
  }
}

function sortJornadas(a, b) {
  return b.id - a.id
}

const Jornada = ({ id, apertura, cierre, onClick }) => (
  <li className="list-group-item list-group-item-action cs-pointer" onClick={() => onClick(id)}>
    <div className="row">
      <div className="col-1">{id}</div>
      <div className="col-4">
        <p><strong>Apertura</strong></p>
        {moment(apertura).format('DD/MM/YYYY HH:mm:ss')}</div>
      <div className="col-7">
        <p><strong>Cierre</strong></p>
        <div>{cierre !== null && moment(cierre).format('DD/MM/YYYY HH:mm:ss')}</div>
      </div>
    </div>
  </li>
)